import * as React from "react";
import Table from "@mui/material/Table";
import Modal from "@mui/material/Modal";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import Paper from "@mui/material/Paper";
import * as ApiCall from "../../API/ApiCalling";
import { Box } from "@mui/system";
import {
  Autocomplete,
  Button,
  TablePagination,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  Typography,
  Chip,
  Tooltip,
  Backdrop,
} from "@mui/material";
import useCrmVariable from "../../../CustomHook/useCrmVariable";
import { useEffect } from "react";
import { useState } from "react";
import ProcessHistoricData from "../ProcessHistoricData/ProcessHistoricData";
import dayjs from "dayjs";
import axios from "axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(25, 118, 210, 0.12)",
    color: "black",
    padding: "7px 5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0 5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CronLIst({
  orgid,
  connname,
  apikey,
  rootFolderID,
  datacenterurl,
  zapikey,
  zuid,
  handleClickOpen,
}) {
  // const {
  //   orgid,
  //   connname,
  //   apikey,
  //   zuid,
  //   zapikey,
  //   datacenterurl,
  //   handleClickOpen,
  // } = useCrmVariable({});
  const [loading, setLoading] = useState(false);
  const [crons, setCrons] = useState([]);
  const [handleSubmitLoading, setHandleSubmitLoading] = useState(false);
  const [individualCron, setIndividualCron] = useState({});
  const [activeCrons, setActiveCrons] = useState(null);
  const [isOpenCreateCronModal, setOpenCreateCronModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (type, data) => {
    setIndividualCron({ type: type, data: data });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setIndividualCron({});
    setOpenModal(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const visibleRows = React.useMemo(
    () => crons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [crons, page, rowsPerPage]
  );

  async function fetchCrons() {
    try {
      console.log({ cronList: "cronList" });
      setLoading(true);
      const cronsResp = await ApiCall.getCronJobs({ orgid, apikey });
      console.log({
        cronList: cronsResp?.data?.filter(
          (cron) => cron?.status != "Completed"
        ),
      });
      setPage(0);
      setRowsPerPage(10);
      setCrons((prev) => cronsResp?.data);
      setActiveCrons((prev) =>
        cronsResp?.data?.filter((cron) => cron?.status != "Completed")
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log({ cronList: error });
    }
  }

  useEffect(() => {
    console.log({ cronList: "cronList" });
    if (orgid && connname && apikey) {
      fetchCrons();
    }
  }, [toggle]);

  const handleModalOpen = () => {
    setOpenCreateCronModal((prev) => true);
  };

  const handleClose = () => setOpenCreateCronModal(false);

  const handleCronUpdate = async () => {
    try {
      setHandleSubmitLoading(true);
      if (individualCron.type === "stop") {
        const updateResp = await ApiCall.updateCronJob({
          orgid,
          apikey,
          settingId: individualCron?.data?.setting_id,
        });

        setHandleSubmitLoading(false);
        handleCloseModal();
        setToggle(!toggle);
      } else {
        const deleteResp = await ApiCall.deleteCronJob({
          orgid,
          apikey,
          settingId: individualCron?.data?.setting_id,
        });
        console.log({ deleteResp });
        setHandleSubmitLoading(false);
        handleCloseModal();
        setToggle(!toggle);
      }
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        p: 4,
        width: "81%",
        mx: "auto",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        mb: 3,
        bgcolor: "#fff",
        mt: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
        {" "}
        <Button
          onClick={handleModalOpen}
          variant="contained"
          size="medium"
          sx={{
            fontWeight: 500,
            fontFamily: "Lato",
            textTransform: "capitalize",
          }}
        >
          <AddIcon sx={{ mr: "5px", fontSize: "16px" }} />
          Bulk Process
        </Button>
      </Box>

      <Divider />
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          textAlign: "center",
          lineHeight: "48px",
          fontSize: 18,
          fontWeight: 600,
          letterSpacing: "0.25px",
          fontFamily: "Roboto",
        }}
      >
        Historical Data Logs
      </Typography>
      <Divider />
      <Box sx={{ my: 1, display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Created Date</StyledTableCell>
                  <StyledTableCell align="left">Module Name</StyledTableCell>
                  <StyledTableCell align="left">
                    Custom View Name
                  </StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  {/* <StyledTableCell align="center">Comment</StyledTableCell> */}
                  <StyledTableCell align="center">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress size={30} />
                    </TableCell>
                  </TableRow>
                ) : crons.length > 0 ? (
                  visibleRows.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        {row?.created_at
                          ? dayjs(row?.created_at).format("MM-DD-YYYY HH:mm")
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.req_body?.module?.displayName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.req_body?.customView?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {row?.status === "Completed" && (
                          <Chip
                            sx={{
                              fontWeight: 500,
                              width: 95,
                              height: 25,
                              bgcolor: "rgba(102, 187, 106, 0.4)",
                              color: "#036e07",
                            }}
                            label={row?.status}
                          />
                        )}
                        {row?.status === "In Progress" && (
                          <Chip
                            sx={{
                              fontWeight: 500,
                              width: 95,
                              height: 25,
                              bgcolor: "rgba(255, 249, 196, 0.8)",
                              color: "black",
                            }}
                            label={row?.status}
                          />
                        )}
                        {!["In Progress", "Completed"].includes(
                          row?.status
                        ) && (
                          <Chip
                            sx={{
                              fontWeight: 500,
                              width: 95,
                              height: 25,
                              bgcolor: "rgba(211, 47, 47, 0.4)",
                              color: "black",
                            }}
                            label={row?.status}
                          />
                        )}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                      {row?.record_count === -1 &&
                        row?.status === "In Progress" &&
                        "Analyzing Data"}
                      {row?.status === "Completed" &&
                        (
                          parseFloat(
                            dayjs(row?.modified_at).diff(
                              dayjs(row?.created_at),
                              "m",
                              true
                            )
                          ) +
                          row.record_count / 50
                        ).toFixed(2)}
                    </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {row?.status === "In Progress" && (
                          <Tooltip title="Stop">
                            <StopCircleIcon
                              onClick={() => handleOpenModal("stop", row)}
                              sx={{
                                cursor: "pointer",
                                color: "rgba(237, 108, 2, 1)",
                              }}
                            />
                          </Tooltip>
                        )}
                        {row?.status !== "In Progress" && (
                          <Tooltip title="Delete">
                            <DeleteOutlineIcon
                              onClick={() => handleOpenModal("delete", row)}
                              sx={{
                                cursor: "pointer",
                                color: "rgba(211, 47, 47, 1)",
                              }}
                            />
                          </Tooltip>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell
                      sx={{ fontWeight: "bold" }}
                      colSpan={5}
                      align="center"
                    >
                      No Data Available
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={crons.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                sx={{
                  borderRadius: 5,
                  bgcolor: "white",
                  width: 500,
                  p: 4,
                  height: 140,
                }}
              >
                {" "}
                <Typography sx={{ mt: 4, fontWeight: "bold", fontSize: 20 }}>
                  Do you want to{" "}
                  {individualCron.type === "stop" ? "stop" : "delete"} this log?
                </Typography>
                <Box
                  sx={{
                    mt: 3,
                    ml: "1rem",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <Button
                    sx={{ width: 120 }}
                    variant="outlined"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={handleSubmitLoading}
                    sx={{ width: 120 }}
                    variant="contained"
                    onClick={handleCronUpdate}
                  >
                    {handleSubmitLoading ? (
                      <CircularProgress
                        sx={{ color: "white", ml: 1 }}
                        size={16}
                      />
                    ) : (
                      "Proceed"
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>

      <Modal
        open={isOpenCreateCronModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ProcessHistoricData
            zapikey={zapikey}
            fetchCrons={fetchCrons}
            previousModule={[]}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            zuid={zuid}
            toggle={toggle}
            setToggle={setToggle}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default CronLIst;
